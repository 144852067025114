'use client';
import { OnbForm } from '@/components/form';
import { pushStepVariables } from '@/redux/flowSlice';
import { pushThirdPartyRedirect } from '@/redux/navigationSlice';
import { Typography } from '@mui/material';
import { paymentMethod } from '@repo/onb-api';
import { PaymentSelection } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useDispatch } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function PaymentMethod({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname?: paymentMethod['name'];
  stepdata?: paymentMethod['variables'];
}): JSX.Element {
  //@todo this logic should be in the button component
  const dispatch = useDispatch();
  const router = useRouter();
  const handleSubmit = ({ paymentMethod, cardId }: paymentMethod['variables']) => {
    if (stepName) {
      dispatch(pushStepVariables({ stepName, variable: 'paymentMethod', value: paymentMethod }));
      if (cardId) dispatch(pushStepVariables({ stepName, variable: 'cardId', value: cardId }));
    }
  };
  const debitCardIndex = stepData?.paymentMethods?.findIndex(({ name }) => name === 'DEBIT_CARD') || 0;
  const orderedPaymentMethods = stepData?.paymentMethods
    ?.slice(debitCardIndex)
    .concat(stepData?.paymentMethods?.slice(0, debitCardIndex));
  return (
    <StepWrapper>
      <Typography variant="h1">¿Cómo querés pagar tu préstamo?</Typography>
      <OnbForm<paymentMethod> stepName={stepName!} hideSubmit>
        {orderedPaymentMethods?.map(({ name, cards, newCardUrl }) => {
          if (name === 'DEBIT_CARD') {
            const rows = cards?.map(({ id, number, issuer, type }) => (
              <PaymentSelection
                key={`card-${id}`}
                data-testid={`card-${id}`}
                gateway="card"
                title={`${formatter(issuer, 'capitalize')}${type === 'DEBIT' ? ' débito' : ''} **** ${number?.slice(-4)}`}
                onClick={() => handleSubmit({ paymentMethod: 'DEBIT_CARD', cardId: id })}
              />
            ));
            if (newCardUrl)
              rows?.push(
                <PaymentSelection
                  data-testid="card"
                  key={'addDebitCard'}
                  gateway="card"
                  title="Agregar una tarjeta de débito nueva"
                  description="De forma simple y segura, vamos a debitar mes a mes de tu cuenta. Para eso a continuación, necesitaremos pedirte los datos de tu tarjeta."
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(pushThirdPartyRedirect(newCardUrl));
                    router.push('/third_party');
                  }}
                />,
              );
            return rows;
          }

          if (name === 'CASH') {
            return (
              <PaymentSelection
                data-testid="cash"
                key={'cash'}
                gateway="cash"
                title="Con efectivo"
                description="Contarás con la opción de descargar el cupón para pagarlo mes a mes en Pago Fácil, Rapipago, Mercado Pago, entre otros."
                onClick={() => handleSubmit({ paymentMethod: 'CASH' })}
              />
            );
          }
        })}
      </OnbForm>
    </StepWrapper>
  );
}
