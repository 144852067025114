import { AlertProps } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { stepNames } from '@repo/onb-api';

export type modalBodyType = 'cancel' | 'backToStore' | 'selectedPlan' | ''; // Add more types if needed.
export type NavigationModel = {
  // isLoading: boolean;
  modalData: {
    id: string;
    open: boolean;
    body: modalBodyType;
    title?: string;
    onClose?: () => void;
  };
  toastData: {
    open: boolean;
    kind?: AlertProps['severity'];
    text: string;
  };
  currentStep?: stepNames;
  thirdPartyRedirect?: string;
};

const initialState: NavigationModel = {
  modalData: {
    id: '',
    open: false,
    body: '',
    title: '',
    onClose: () => '',
  },
  toastData: { open: false, kind: undefined, text: '' },
  currentStep: undefined,
  thirdPartyRedirect: '',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    pushNavigation: (state, action: { payload: NavigationModel }) => ({
      ...state,
      ...action.payload,
    }),
    showModal: (
      state,
      action: {
        payload: {
          id?: string;
          body: modalBodyType;
          title: string;
          onClose?: () => void;
        };
      },
    ) => ({
      ...state,
      modalData: { ...state.modalData, ...action.payload, open: true },
    }),
    hideModal: (state) => ({
      ...state,
      modalData: { ...initialState.modalData, body: state.modalData.body, title: state.modalData.title },
    }),
    showToast: (
      state,
      action: {
        payload: {
          text: string;
          kind?: AlertProps['severity'];
        };
      },
    ) => ({
      ...state,
      toastData: { ...action.payload, open: true },
    }),
    hideToast: (state) => ({
      ...state,
      toastData: { ...state.toastData, open: false },
    }),
    pushStepName: (state, action: { payload: stepNames }) => ({
      ...state,
      currentStep: action.payload,
    }),
    pushThirdPartyRedirect: (state, action: { payload: string }) => ({
      ...state,
      thirdPartyRedirect: action.payload,
    }),
    resetNavigation: () => initialState,
  },
});

export const {
  pushNavigation,
  showModal,
  hideModal,
  showToast,
  hideToast,
  pushStepName,
  pushThirdPartyRedirect,
  resetNavigation,
} = navigationSlice.actions;

export default navigationSlice.reducer;
