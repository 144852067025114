'use client';
import { revalidateLandingData } from '@/app/(withLayout)/flow/actions';
import { pushErrorData, resetErrors } from '@/redux/errorSlice';
import { pushFlowData } from '@/redux/flowSlice';
import { showModal } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { ButtonProps } from '@mui/material';
import { createInstance, step, submitStep } from '@repo/onb-api';
import { Form } from '@repo/ui';
import { getClientCookie, setClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendOnbEvent } from '../newRelic';
import { formProps } from './types';

/**
 * Renders an onboarding form component with step submit logic.
 *
 * @param {string} stepName - The name of the step.
 * @param {boolean} hideSubmit - Whether to hide the submit button in order to handle it manually.
 * @param {string} [submitLabel] - The label for the submit button default: continuar
 * @param {string} [cancelLabel] - The label for the cancel button.
 * @param {Object} [rest] - Additional props for the Form component.
 * @return {JSX.Element} The rendered OnbForm component.
 */
export const OnbForm = <T,>({
  stepName,
  hideSubmit,
  submitLabel,
  cancelLabel,
  disabled,
  ...rest
}: formProps): JSX.Element => {
  const { steps } = useSelector((state: RootState) => state.flowdata);
  let instanceId = getClientCookie('instanceId');
  const dispatch = useDispatch();
  const router = useRouter();
  const [submitting, setSubmitting] = useState(false);
  const variables = steps?.find((step) => step.name === stepName)?.variables || {};
  const internal = getClientCookie('internal') === 'true';

  const buttons: ButtonProps[] = [
    {
      name: 'submit',
      disabled: disabled,
      sx: { display: hideSubmit ? 'none' : 'block' },
      children: submitLabel ? submitLabel : 'Continuar',
    },
  ];

  if (!internal) {
    buttons.push({
      name: 'cancel',
      // disabled: submitting,
      children: cancelLabel
        ? cancelLabel
        : process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-efectivo'
          ? 'Volver al Inicio'
          : 'Volver a la tienda',
      onClick:
        process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-efectivo'
          ? () => {
              dispatch(
                showModal({
                  body: 'cancel',
                  title: 'Si volvés al inicio todos los datos ingresados se perderán',
                }),
              );
            }
          : () => {
              dispatch(
                showModal({
                  body: 'backToStore',
                  title: 'Si cancelas, todos los datos ingresados se perderán. ¿Estás seguro?',
                }),
              );
            },
    });
  }
  const submitHandler = ({ retry, instanceId }: { retry: number; instanceId: string }) => {
    let redirectStep = '';
    submitStep<T>({
      instanceId,
      step: {
        name: stepName,
        variables,
      } as T,
    })
      .then((response) => {
        if (response?.hardError) {
          sendOnbEvent({
            type: 'error',
            subtype: 'submitStep',
            data: { error: response?.error },
            severity: 'high',
          });
          if (retry > 0) {
            submitHandler({ retry: retry - 1, instanceId });
          } else {
            redirectStep = '/error';
          }
        } else if (response?.softError) {
          sendOnbEvent({
            type: 'error',
            subtype: 'submitStep',
            data: { attribute: response?.attribute, error: response?.error },
            severity: 'low',
          });
          dispatch(pushErrorData({ attribute: response?.attribute, error: response?.error }));
          setSubmitting(false);
        } else {
          dispatch(pushFlowData(response));
          dispatch(resetErrors());
          redirectStep = `/flow/${response?.steps?.[response?.steps?.length - 1]?.name}` || '/error';
        }
      })
      .catch((error) => {
        sendOnbEvent({
          type: 'error',
          subtype: 'submitStep',
          data: { error: error?.error },
          severity: 'high',
        });
        redirectStep = '/error';
      })
      .finally(() => {
        if (redirectStep !== '') router.push(redirectStep);
      });
  };

  return (
    <Form
      submitting={submitting}
      formProps={{
        onSubmit: async () => {
          try {
            setSubmitting(true);
            dispatch(resetErrors());
            /** If no instance present on redux creates a new one before submition */
            if (!instanceId) {
              try {
                const initialData = await createInstance({});
                if (initialData.hardError) {
                  sendOnbEvent({
                    type: 'error',
                    subtype: 'createInstance',
                    severity: 'high',
                  });
                  router.push('/error');
                } else {
                  setClientCookie('instanceId', initialData.id);
                  dispatch(
                    pushFlowData({
                      ...initialData,
                      steps: initialData?.steps?.map((s) =>
                        s.name === stepName ? ({ ...s, variables: variables } as step) : s,
                      ),
                    }),
                  );
                  if (initialData.steps?.[(initialData.steps?.length || 1) - 1]?.name !== stepName) {
                    revalidateLandingData();
                    router.push(`flow/${initialData.steps?.[initialData?.steps?.length - 1]?.name}` || 'error');
                  }
                  instanceId = initialData.id;
                }
              } catch (error: any) {
                sendOnbEvent({
                  type: 'error',
                  subtype: 'createInstance',
                  data: { error: error?.error },
                  severity: 'high',
                });
                router.push('/error');
              }
            }
            if (instanceId) {
              submitHandler({ retry: 1, instanceId });
            }
          } catch (error: any) {
            console.error('Error 1969', error);
            //@todo - check if event is always sending.
            sendOnbEvent({ type: 'error', subtype: 'submitStep', data: { error: error?.error }, severity: 'high' });
            router.push('/error');
          }
        },
        ...rest,
      }}
      buttonProps={buttons}
    />
  );
};
