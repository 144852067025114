'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { pushStepVariables } from '@/redux/flowSlice';
import { FormGroup, FormLabel, TextField, Typography } from '@mui/material';
import { loanAmountAndPurpose } from '@repo/onb-api';
import { Checkbox } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function LoanAmountAndPurpose({
  stepname: stepName,
}: {
  stepname: loanAmountAndPurpose['name'];
}): JSX.Element {
  const dispatch = useDispatch();
  const [loanPurposes, setLoanPurposes] = useState({
    electro: false,
    casa: false,
    ropa: false,
    otro: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoanPurposes({
      ...loanPurposes,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    dispatch(
      pushStepVariables({
        stepName,
        variable: 'loanPurposes',
        value: Object.entries(loanPurposes).reduce<string[]>((acc, [key, value]) => (value ? [...acc, key] : acc), []),
      }),
    );
  }, [loanPurposes, dispatch]);

  const { electro, casa, ropa, otro } = loanPurposes;
  return (
    <StepWrapper>
      <Typography variant="h1">
        ¿Podrías contarnos cuál es el monto que tenés en mente y para qué lo necesitás?
      </Typography>
      <OnbForm<loanAmountAndPurpose> stepName={stepName!}>
        <TextField
          label="Monto pretendido en pesos argentinos"
          autoFocus
          placeholder="Ej: 200000"
          slotProps={{ htmlInput: { maxLength: 12, inputMode: 'numeric', 'data-testid': 'desiredAmount' } }}
          {...connectedField({
            stepName,
            name: 'desiredAmount',
            required: true,
            beforeRender: (value) => formatter(value, 'currency'),
            beforeChange: (value) => Number(value.replaceAll(/\D+/g, '')),
            errorHandler: {
              type: 'text',
              errorText: 'Ingresa un monto válido',
              validate: (value) => value.length > 6,
            },
          })}
        />
        <FormGroup
          aria-labelledby="loanPurposes-label"
          data-testid="loanPurposes"
          sx={{
            gap: 1,
            '& >label:nth-last-of-type(1)': {
              margin: '0px',
            },
          }}
        >
          <FormLabel id="loanPurposes-label">Seleccioná el destino</FormLabel>
          <Checkbox name="electro" checked={electro} onChange={handleChange} label="Electrodomésticos" />
          <Checkbox name="casa" checked={casa} onChange={handleChange} label="Casa" />
          <Checkbox name="ropa" checked={ropa} onChange={handleChange} label="Ropa" />
          <Checkbox name="otro" checked={otro} onChange={handleChange} label="Otro" />
        </FormGroup>
      </OnbForm>
    </StepWrapper>
  );
}
