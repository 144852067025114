'use client';

import { OnbForm } from '@/components/form';
import { Stack, Typography } from '@mui/material';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { OrderCard } from '@repo/ui';
import { deleteClientCookie, parseJwt, setClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import StepWrapper from './flow/stepWrapper';

export default function InitializePayments({ orderToken, internal }: { orderToken: string; internal: boolean }) {
  const decodedJwt = parseJwt(orderToken) as getOrderCredentialJWT;
  const expireDate = new Date(decodedJwt.exp * 1000).toUTCString();
  const [disableForm, setDisableForm] = useState(false);
  const { merchantName, merchantOrderId, price } = decodedJwt;
  const router = useRouter();
  const initializeFlow = () => {
    setDisableForm(true);
    deleteClientCookie('instanceId');
    setClientCookie('internal', internal ? 'true' : 'false');
    setClientCookie('orderToken', orderToken, expireDate);
    setClientCookie('tokenData', orderToken);
    setClientCookie('returnURLError', decodedJwt.returnURLError);
    setClientCookie('returnURLSuccess', decodedJwt.returnURLSuccess);
    router.push('/flow');
  };
  return (
    <StepWrapper>
      <Stack gap={2}>
        <Typography variant="h1">Comprá en cuotas con Directo</Typography>
        <Typography variant="h2">¡Confirmá que los detalles de la operación sean estos y continuá!</Typography>
        <OrderCard merchantName={merchantName} merchantOrderId={merchantOrderId} price={price} />
      </Stack>
      <OnbForm onSubmit={initializeFlow} disabled={disableForm} />
      <Stack gap={2} width={'100%'}></Stack>
    </StepWrapper>
  );
}
