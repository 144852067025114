import { createSlice } from '@reduxjs/toolkit';
import { errorNames, variables } from '@repo/onb-api';

export type errorModel = {
  attribute?: keyof variables;
  error?: errorNames;
  clearErrors?: boolean;
};

const initialState: errorModel = {
  attribute: undefined,
  error: undefined,
  clearErrors: false,
};

export const errorSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    pushErrorData: (state, action: { payload: errorModel }) => ({
      ...state,
      ...action.payload,
      clearErrors: false,
    }),
    clearErrors: (state) => ({ ...state, clearErrors: true }),
    resetErrors: () => {
      return initialState;
    },
  },
});

export const { pushErrorData, clearErrors, resetErrors } = errorSlice.actions;

export default errorSlice.reducer;
