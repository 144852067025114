'use client';

import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { getClientCookie, parseJwt } from '@repo/utils';
import { stepNames } from '../../types';

/**
 * Appends variables to the flow based on the step.
 *
 * @param {stepNames} stepName The name of the step.
 * @returns {Record<string, unknown> | null} The variables to append.
 */
export const appendVariablesMap = (stepName: stepNames) => {
  switch (stepName) {
    case 'REPAYMENT_TERMS':
      return process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas'
        ? {
            desiredAmount:
              parseFloat((parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT)?.price) || 0,
          }
        : null;
    default:
      return null;
  }
};
