import { errorNames, screenNames, stepNames } from './types';

export const stepNamesConst: Record<stepNames, stepNames> = {
  CUSTOMER_IDENTIFICATION: 'CUSTOMER_IDENTIFICATION',
  CUSTOMER_REGISTRATION: 'CUSTOMER_REGISTRATION',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION',
  SMS_VERIFICATION: 'SMS_VERIFICATION',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  LOAN_AMOUNT_AND_PURPOSES: 'LOAN_AMOUNT_AND_PURPOSES',
  REPAYMENT_TERMS: 'REPAYMENT_TERMS',
  BANK_ACCOUNT_REFERENCE: 'BANK_ACCOUNT_REFERENCE',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  IDENTITY_VERIFICATION_STATUS_PENDING: 'IDENTITY_VERIFICATION_STATUS_PENDING',
  IDENTITY_VERIFICATION_START: 'IDENTITY_VERIFICATION_START',
  REVIEW_AND_SUBMIT: 'REVIEW_AND_SUBMIT',
  REJECTION: 'REJECTION',
  SUCCESS: 'SUCCESS',
};

export const screenNamesConst: Record<screenNames, screenNames> = {
  ...stepNamesConst,
  ERROR: 'ERROR',
  THIRD_PARTY: 'THIRD_PARTY',
};

export const errorTypes: Record<errorNames, string> = {
  INVALID_ATTRIBUTE_TYPE: 'El tipo de dato ingresado no es válido',
  INVALID_ATTRIBUTE_LENGTH: 'El largo del valor no es válido',
  INVALID_ATTRIBUTE_RANGE: 'El valor ingresado está fuera de rango',
  INVALID_ATTRIBUTE_ENUM_CODE: 'El código no es válido',
  INVALID_ATTRIBUTE_FORMAT: 'El valor ingresado no posee el formato correcto',
  INVALID_BANK_ACCOUNT_REFERENCE: 'Esta cuenta no es válida',
  REQUIRED_ATTRIBUTE: 'Este campo es requerido',
  INVALID_ATTRIBUTE: 'El valor ingresado no es válido ',
  MOBILE_PHONE_NUMBER_ALREADY_REGISTERED: 'El número de celular ya se encuentra registrado',
  EMAIL_ALREADY_REGISTERED: 'El correo ya se encuentra registrado',
  INVALID_OTP: 'El código es incorrecto',
};

export const provinces = [
  'BUENOS_AIRES',
  'CAPITAL_FEDERAL',
  'CATAMARCA',
  'CHACO',
  'CHUBUT',
  'CORDOBA',
  'CORRIENTES',
  'ENTRE_RIOS',
  'FORMOSA',
  'JUJUY',
  'LA_PAMPA',
  'LA_RIOJA',
  'MENDOZA',
  'MISIONES',
  'NEUQUEN',
  'RIO_NEGRO',
  'SALTA',
  'SAN_JUAN',
  'SAN_LUIS',
  'SANTA_CRUZ',
  'SANTA_FE',
  'SANTIAGO_DEL_ESTERO',
  'TIERRA_DEL_FUEGO',
  'TUCUMAN',
];

export const onbCompleteFlow = {
  id: 'ad5b0908-9450-426f-b363-20be7482c0b6',
  flowName: 'onbEfectivo',
  variables: {
    email: 'a@a.com',
    gender: 'MALE',
    lastName: 'SUAREZ',
    province: 'FORMOSA',
    firstName: 'GABRIEL GONZALO',
    policyMaximumAmount: 500000.0,
    policyMinimumAmount: 0.0,
    desiredAmount: 10000,
    documentNumber: '45511908',
    mobilePhoneNumber: '1161998576',
    requestedAmount: 250000,
  },
  steps: [
    {
      id: 'd966adfb-59a8-4bd1-baa3-ac101ad4e7f3',
      name: 'CUSTOMER_IDENTIFICATION',
      variables: {
        gender: 'MALE',
        documentNumber: '12123123',
      },
      submittedAt: '2024-05-12T12:32:57',
    },
    {
      id: '473dbbfa-ed46-409f-9d4c-b8f16e79ffb3',
      name: 'CUSTOMER_REGISTRATION',
      variables: {
        fullNames: ['Sergio Dembis', 'Alberto Mágico'],
        fullName: 'Sergio Dembis',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'CONTACT_INFORMATION',
      variables: {
        email: 'a@a.com',
        mobilePhoneNumber: '1101091939',
        province: 'SAN_JUAN',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'SMS_VERIFICATION',
      variables: {
        smsOtp: '1234',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'LOAN_AMOUNT_AND_PURPOSES',
      variables: {
        desiredAmount: 400000,
        loanPurposes: ['electro', 'other'],
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'REPAYMENT_TERMS',
      variables: {
        requestedAmount: 400000,
        installmentPlanId: 31,
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'BANK_ACCOUNT_REFERENCE',
      variables: {
        bankAccountReference: '0290000100000000058382',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'PAYMENT_METHOD',
      variables: {
        paymentMethod: 'CARD',
        cardId: '1234',
      },
    },
    // {
    //   id: '663ccf91-a34c-4bda-a697-621a526bf739',
    //   name: 'EMAIL_VERIFICATION',
    // },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'IDENTITY_VERIFICATION_START',
      variables: {
        clientId: '64efa006d9dcda001b258cdb',
        flowId: '64efa006d9dcda001b258cd9',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'REVIEW_AND_SUBMIT',
      variables: {
        hasTermsAccepted: true,
        requestedAmount: 334000,
        installmentAmount: 102977,
        installmentCount: 9,
        installmentRates: {
          CFT: 271,
          TEA: 1051.27,
          TEM: 22.58,
          TNA: 271,
          CFTNA: 273.69,
          CFTTEA: 1080.66,
        },
        installmentFirstDueDate: '2024-10-07',
        paymentMethod: 'CASH',
        bankAccountReference: '0290000100000000058382',
        cardType: 'DEBIT',
        cardIssuer: 'Visa',
        cardNumber: '2345********3345',
        paymentSchedule: [
          {
            number: 1,
            amount: 102977,
            dueDate: '2024-10-07',
          },
          {
            number: 2,
            amount: 102977,
            dueDate: '2024-11-07',
          },
          {
            number: 3,
            amount: 102977,
            dueDate: '2024-12-07',
          },
          {
            number: 4,
            amount: 102977,
            dueDate: '2025-01-07',
          },
          {
            number: 5,
            amount: 102977,
            dueDate: '2025-02-07',
          },
          {
            number: 6,
            amount: 102977,
            dueDate: '2025-03-07',
          },
          {
            number: 7,
            amount: 102977,
            dueDate: '2025-04-07',
          },
          {
            number: 8,
            amount: 102977,
            dueDate: '2025-05-07',
          },
          {
            number: 9,
            amount: 102977,
            dueDate: '2025-06-07',
          },
        ],
        documentNumber: '32784234',
        gender: 'MALE',
        fullName: 'KURYLEC, ANDRES',
        birthDate: '1995-11-03',
        taxpayerId: '20327842340',
        email: 'akurylec@directo.com.ar',
        mobilePhoneNumber: '1161998576',
        province: 'BUENOS_AIRES',
        termsAndConditions:
          '<p>POR FAVOR, LEA CUIDADOSAMENTE ESTOS TÉRMINOS Y CONDICIONES DE USO DEL PRESENTE SITIO WEB, PARA SU CORRECTA UTILIZACIÓN. Los presentes términos y condiciones generales de uso (los “Términos y Condiciones”) serán aplicables al uso y contratación de los servicios ofrecidos por CREDITO DIRECTO S.A. CUIT 30-71210113-6 con domicilio en Av. Corrientes Nº 1174, Piso 7°, Ciudad Autónoma de Buenos Aires ( en adelante "Directo"), a través de la aplicación informática diseñada para ser ejecutada en teléfonos inteligentes y otros dispositivos móviles denominada directo (la "Aplicación"), a través del sitio web Crédito Directo  y/o relacionados de cualquier manera con el sitio web (en adelante, el “Sitio Web”). Cualquier persona (en adelante "Usuario" o en plural "Usuarios") que desee acceder y/o utilizar el Sitio Web y/o la Aplicación y/o utilizar cualquiera de los servicios en él ofrecidos deberá leer atentamente los Términos y Condiciones y, en su caso, aceptar dichos Términos y Condiciones sin restricción, reserva o modificación alguna con carácter previo a completar su proceso de registración en Sitio Web y/o la Aplicación, junto con todas las demás políticas de Directo que se encontraren vigentes, o tal como las mismas sean modificadas y/o actualizadas por Directo en el futuro.</p>',
      },
    },
    {
      id: '663ccf91-a34c-4bda-a697-621a526bf739',
      name: 'SUCCESS',
    },
  ],
  createdAt: '2024-05-12T09:32:24',
};
