import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { SafeSpacePadder } from '@repo/ui';

export default function Loading() {
  return (
    <SafeSpacePadder>
      <Grid
        container
        sx={{
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: '100%',
          justifyContent: 'space-between',
          paddingBottom: { xs: 4, md: 0 },
          gap: 2,
        }}
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Skeleton variant="rounded" animation="wave" sx={{ width: '80%', height: 44 }} />
          <Skeleton variant="rounded" animation="wave" sx={{ width: '40%', height: '2rem' }} />
          <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: 44 }} />
          <Skeleton variant="rounded" animation="wave" sx={{ width: '40%', height: '2rem', mt: 2 }} />
          <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: 44 }} />
          <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: 44 }} />
        </Grid>
        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: 44, mt: 2 }} />
      </Grid>
    </SafeSpacePadder>
  );
}
