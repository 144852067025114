'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { TextField, Typography } from '@mui/material';
import { bankAccountReference } from '@repo/onb-api';
import StepWrapper from './stepWrapper';

export default function BankAccountReference({
  stepname: stepName,
}: {
  stepname?: bankAccountReference['name'];
}): JSX.Element {
  return (
    <StepWrapper>
      <Typography variant="h1">Ingresá los datos de la cuenta en la que querés recibir el dinero</Typography>
      <Typography variant="h2">La cuenta debe estar registrada a nombre del DNI ingresado.</Typography>
      <OnbForm<bankAccountReference> stepName={stepName!} submitLabel="Validar">
        <TextField
          label="Ingresá tu CBU/CVU"
          autoFocus
          placeholder="Ej: 285059094009041813520"
          slotProps={{ htmlInput: { 'data-testid': 'bankAccountReference', inputMode: 'numeric', maxLength: 22 } }}
          helperText="Tenés 3 intentos para validar tus datos, 
          asegurate de que estos estén correctamente ingresados."
          {...connectedField({
            stepName,
            name: 'bankAccountReference',
            required: true,
            beforeChange: (value) => value.replaceAll(/\D/g, ''),
            errorHandler: {
              type: 'text',
              errorText: 'El CBU/CVU ingresado no es válido. Revisalo y volvé a intentar',
              validate: (value) => value.length === 22,
            },
          })}
        />
      </OnbForm>
    </StepWrapper>
  );
}
