import { type stepNames, type variables } from '../../types';

/**
 * Maps step names to their corresponding variables.
 *
 * will use the first local variable available as the remote variable
 *
 * @type {Record<stepNames, [{ local: (keyof variables)[]; remote: string }] | undefined>}
 */
export const initializeVariablesMap: Record<stepNames, [{ local: (keyof variables)[]; remote: string }] | undefined> = {
  REPAYMENT_TERMS: [
    {
      local: [
        'requestedAmount',
        process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas' ? 'desiredAmount' : 'policyMaximumAmount',
      ],
      remote: 'amount',
    },
  ],
  CUSTOMER_IDENTIFICATION: undefined,
  CUSTOMER_REGISTRATION: undefined,
  CONTACT_INFORMATION: undefined,
  SMS_VERIFICATION: undefined,
  EMAIL_VERIFICATION: undefined,
  LOAN_AMOUNT_AND_PURPOSES: undefined,
  BANK_ACCOUNT_REFERENCE: undefined,
  PAYMENT_METHOD: undefined,
  IDENTITY_VERIFICATION_STATUS_PENDING: undefined,
  IDENTITY_VERIFICATION_START: undefined,
  REVIEW_AND_SUBMIT: undefined,
  REJECTION: undefined,
  SUCCESS: undefined,
};
