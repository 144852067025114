import { createSlice } from '@reduxjs/toolkit';
import { instanceResponse, step, stepNames, steps, variables, variablesOf } from '@repo/onb-api';

const initialState: instanceResponse = {
  id: '',
  steps: [],
  variables: {},
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    pushFlowData: (state, action: { payload: instanceResponse }) => ({
      ...state,
      ...action.payload,
    }),
    pushDummyStep: (state, action: { payload: stepNames }) => ({
      ...state,
      // steps: [...state.steps, { id: '', name: action.payload, variables: {} } as step],
      steps: [{ id: '', name: action.payload, variables: {} } as step],
    }),
    // @todo: narrow variable type based on step
    pushStepVariables: (
      state,
      action: { payload: { stepName: stepNames; variable: keyof variables; value: variables[keyof variables] } },
    ) => {
      const stepIndex = state.steps.findIndex((step) => step.name === action.payload.stepName);
      return {
        ...state,
        steps: state.steps.map((step, index) =>
          index === stepIndex //&& step.variables?.[action.payload.variable] !== undefined
            ? ({
                ...step,
                variables: {
                  ...step.variables,
                  [action.payload.variable]: action.payload.value,
                },
              } as typeof step)
            : step,
        ),
      };
    },
    pushInitializeStepData: (
      state,
      action: { payload: { stepName: stepNames; variables?: variablesOf<steps[typeof action.payload.stepName]> } },
    ) => {
      const stepIndex = state.steps.findIndex((step) => step.name === action.payload.stepName);
      return {
        ...state,
        steps: state.steps.map((step, index) =>
          index === stepIndex
            ? ({
                ...step,
                initialized: true,
                variables: { ...step.variables, ...action.payload.variables },
              } as typeof step)
            : step,
        ),
      };
    },
    deinitializeStep: (state, action: { payload: stepNames }) => {
      const stepIndex = state.steps.findIndex((step) => step.name === action.payload);
      return {
        ...state,
        steps: state.steps.map((step, index) => (index === stepIndex ? { ...step, initialized: false } : step)),
      };
    },
    appendVariables: (state, action: { payload: { variables: variables } }) => {
      return {
        ...state,
        variables: { ...state.variables, ...action.payload.variables },
      };
    },
    resetFlow: () => {
      // deleteClientCookie('instanceId');
      return initialState;
    },
  },
});

export const {
  pushFlowData,
  pushStepVariables,
  pushDummyStep,
  pushInitializeStepData,
  deinitializeStep,
  appendVariables,
  resetFlow,
} = flowSlice.actions;

export default flowSlice.reducer;
