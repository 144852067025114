'use client';
import { RootState } from '@/redux/store';
import { MaterialIcon } from '@repo/ui';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';

/**
 * Back arrow icon button that navigates to previous page when clicked using next navigation router.
 *
 * @return {JSX.Element} The rendered back arrow icon button.
 */
export const BackArrow = () => {
  const router = useRouter();
  const steps = useSelector((state: RootState) => state.flowdata.steps);
  const currentStepName = useSelector((state: RootState) => state.navigationdata.currentStep);
  const currentStep = steps?.find((s) => s.name === currentStepName);
  const previousStep = currentStep && steps?.[steps?.indexOf(currentStep) - 1]?.name;

  return (
    previousStep && (
      <MaterialIcon
        icon="arrow_back"
        color="primary"
        sx={{ cursor: 'pointer' }}
        onClick={() => router.push(previousStep ? `/flow/${previousStep}` : '/error')}
      />
    )
  );
};
