'use client';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { success } from '@repo/onb-api';
import { COLORS, Doodles, Logo } from '@repo/ui';
import { deleteClientCookie, formatter, getClientCookie } from '@repo/utils';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import ReactConfetti from 'react-confetti';

export default function SuccessPayments({ stepdata: stepData }: { stepdata?: success['variables'] }) {
  const redirectInterval = useRef(0);
  const router = useRouter();
  const [redirectTimeout, setRedirectTimeout] = useState(10);
  const { installmentPlanType, firstName } = stepData || {};
  const internal = getClientCookie('internal') === 'true';
  useEffect(() => {
    if (!internal) {
      redirectInterval.current = window.setTimeout(() => {
        setRedirectTimeout((t) => t - 1);
      }, 1000);
      if (redirectTimeout <= 0) {
        clearTimeout(redirectInterval.current);
        const returnURLSuccess = getClientCookie('returnURLSuccess');
        deleteClientCookie('returnURLError');
        deleteClientCookie('returnURLSuccess');
        router.push(returnURLSuccess || '/');
      }
    }
  }, [internal, redirectTimeout]);
  return (
    <Grid
      sx={{
        border: '2px solid #fff',
        borderRadius: { xs: 0, md: '24px' },
        // borderTopLeftRadius: { xs: 0, md: '24px' },
        // borderTopRightRadius: { xs: 0, md: '24px' },
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '100%',
        width: '100%',
        paddingBottom: { xs: 4, md: 0 },
      }}
    >
      <Stack sx={{ width: '100%', flex: 1 }}>
        <Stack alignItems="center" gap={4} bgcolor={COLORS.background[0]} sx={{ flex: 1, justifyContent: 'center' }}>
          <Stack gap={2} alignItems={'center'} sx={{ pt: 4, pb: 8, px: { xs: 2, md: 8 } }}>
            <Doodles type="success" />
            <Typography variant="h1" sx={{ pt: 2 }}>
              ¡Felicitaciones {formatter(firstName, 'capitalize')}!
            </Typography>
            {installmentPlanType === 'DEFERRED_PAYMENT' && (
              <Typography color="secondary">
                ya debitamos la primera cuota y te enviaremos el resumen a tu mail.
              </Typography>
            )}
          </Stack>
        </Stack>
        {!internal ? (
          <Stack
            alignItems="center"
            gap={1}
            sx={{
              borderRadius: 6,
              mt: -4,
              bgcolor: 'background.paper',
              color: 'primary.main',
              pt: { xs: 4, md: 4 },
              pb: 0,
              px: { xs: 2, md: 8 },
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Te redirigiremos a la tienda para que finalices la compra en 00:
              {redirectTimeout >= 10 ? redirectTimeout : `0${redirectTimeout}`} segundos
            </Typography>
          </Stack>
        ) : (
          <Stack
            alignItems="center"
            gap={1}
            sx={{
              borderRadius: 6,
              mt: -4,
              bgcolor: 'background.paper',
              color: 'primary.main',
              pt: { xs: 4, md: 4 },
              pb: 0,
              px: { xs: 2, md: 8 },
            }}
          >
            <Typography variant="h1" color="primary" sx={{ mb: 2, fontWeight: '400' }}>
              Podés ver los detalles en tu perfil
            </Typography>
            <Logo height={28} />
          </Stack>
        )}
      </Stack>
      {internal && (
        <Box sx={{ width: '100%', p: { xs: 2, md: 8 }, pt: { xs: 4, md: 4 } }}>
          <Link href={process.env.NEXT_PUBLIC_CUSTOMER_ROUTE || ''}>
            <Button fullWidth variant="contained">
              Ingresar a mi cuenta
            </Button>
          </Link>
        </Box>
      )}
      <ReactConfetti recycle={false} numberOfPieces={500} />
    </Grid>
  );
}
