'use client';

import Loading from '@/app/(withLayout)/loading';
import { appendVariables, pushDummyStep, pushFlowData, pushInitializeStepData } from '@/redux/flowSlice';
import { pushStepName } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import {
  appendVariablesMap,
  initializeStep,
  initializeVariablesMap,
  instanceResponse,
  step,
  stepNames,
  steps,
  variables,
} from '@repo/onb-api';
import { deleteClientCookie, setClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendOnbEvent } from './newRelic';

/**
 * @todo this should receive initstep also from server if posible
 *
 * @param {instanceResponse} initialData - The initial flow data.
 * @return {JSX.Element} The JSX element representing the loading state.
 */
// export function ResumeClientFlow({
//   initialData,
//   children,
// }: {
//   initialData: instanceResponse;
//   children: JSX.Element;
// }): JSX.Element {
//   const dispatch = useDispatch();
//   const router = useRouter();
//   const lastStep = initialData.steps?.[initialData?.steps?.length - 1]?.name;
//   // router.replace(lastStep ? `/flow/${lastStep}` : '/error');
//   if (typeof window !== 'undefined') window.history.replaceState(null, '', lastStep ? `/flow/${lastStep}` : '/error');
//   const storeLastStep = useSelector(
//     (state: RootState) => state.flowdata.steps?.[state.flowdata.steps?.length - 1]?.name,
//   );
//   useEffect(() => {
//     dispatch(pushFlowData(initialData));
//   }, [dispatch, router, initialData]);
//   return storeLastStep === lastStep ? children : <Loading />;
// }

/**
 * Initializes the client flow by loading the initial flow state with Redux and navigating to the last step of the flow.
 *
 * @param {instanceResponse} initialData - The initial flow data.
 * @return {JSX.Element} The JSX element representing the loading state.
 */
export function InitializeClientFlow({ initialData }: { initialData: instanceResponse }): JSX.Element {
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    dispatch(pushFlowData(initialData));
    const lastStep = initialData.steps?.[initialData?.steps?.length - 1]?.name;
    router.replace(lastStep ? `/flow/${lastStep}` : '/error');
  }, [dispatch, router, initialData]);
  return <Loading />;
}

// /**
//  * Initializes the client flow by loading the initial flow state with Redux and navigating to the last step of the flow.
//  *
//  * @param {instanceResponse} initialData - The initial flow data.
//  * @return {JSX.Element} The JSX element representing the loading state.
//  */
// export function InitializeServerCookies({ jwt, instanceId }: { jwt?: string; instanceId?: string }) {
//   if (jwt) setClientCookie('ecommerce_token', jwt);
//   if (instanceId) setClientCookie('instanceId', instanceId);
//   const router = useRouter();
//   router.replace('/flow');
//   return <Loading />;
// }

/**
 * Initializes the client flow by setting the dummy step in Redux and deleting the instanceId cookie.
 *
 * @param {stepNames} stepName - The name of the step to set as dummy.
 * @param {JSX.Element} children - The JSX element to render.
 * @return {JSX.Element} The JSX element representing the loading state.
 */
export function InitializeDummyFlow({
  stepName,
  children,
}: {
  stepName: stepNames;
  children: JSX.Element;
}): JSX.Element {
  deleteClientCookie('instanceId');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pushStepName(stepName));
    dispatch(pushDummyStep(stepName));
  }, [dispatch]);
  return children;
}

/**
 * Checks if the client data is correct and renders the provided children if it is.
 * If the client data is not correct, it redirects to the flow page.
 *
 * @param {stepNames} stepName - The name of the step to check.
 * @param {boolean} [skip] - Flag to skip the check. Defaults to false.
 * @param {JSX.Element} children - The JSX element to render if the client data is correct.
 * @return {JSX.Element} The JSX element to render.
 */
export function CheckClientData({
  instanceId,
  stepName,
  children,
}: {
  jwt?: string;
  instanceId?: string;
  stepName: stepNames;
  children: JSX.Element;
}): JSX.Element {
  if (instanceId) {
    setClientCookie('instanceId', instanceId);
  }
  const router = useRouter();
  const dispatch = useDispatch();
  const { variables, steps, id } = useSelector((state: RootState) => state.flowdata);
  const [currentStep, setCurrentStep] = useState<step>();
  const [childWithProps, setChildWithProps] = useState<JSX.Element>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [areVariablesAppended, setAreVariablesAppended] = useState(false);
  useEffect(() => {
    const _currentStep = steps.find((s) => s.name === stepName);
    if (!_currentStep) {
      // At this point we are missing the step data, redirect to the flow page
      router.replace('/flow');
      router.refresh();
    } else {
      setCurrentStep(_currentStep);
    }
  }, [steps, stepName]);
  useEffect(() => {
    if (currentStep?.initialized) {
      if (currentStep.isEndOfFlow) {
        deleteClientCookie('instanceId');
        deleteClientCookie('orderToken');
      }
      setChildWithProps(React.cloneElement(children, { stepdata: currentStep?.variables || {} }));
    }
  }, [currentStep, children]);
  useEffect(() => {
    if (!isInitialized && id && stepName === currentStep?.name && areVariablesAppended) {
      let error = false;
      setIsInitialized(true);
      initializeStep<steps[typeof stepName]>({
        instanceId: id,
        name: stepName,
        variables: getInitializeVariables({ name: stepName, variables }),
      })
        .then((response) => {
          if (response.hardError || response.softError) {
            sendOnbEvent({ type: 'error', subtype: 'initStep', data: { stepName } });
            error = true;
          } else {
            dispatch(pushInitializeStepData({ stepName, variables: response.variables }));
            dispatch(pushStepName(stepName));
          }
        })
        .catch(() => {
          console.error('Initialize Step Error');
          error = true;
        })
        .finally(() => {
          if (error) {
            sendOnbEvent({ type: 'error', subtype: 'initStep', data: { stepName } });
            router.push('/error');
          }
        });
    }
    //Will try to append local variables before step initialization
    if (!isInitialized && id && stepName === currentStep?.name && !areVariablesAppended) {
      const variablesToAppend = appendVariablesMap(stepName);
      if (variablesToAppend) {
        dispatch(appendVariables({ variables: variablesToAppend }));
      }
      setAreVariablesAppended(true);
    }
  }, [isInitialized, id, stepName, dispatch, variables, currentStep, areVariablesAppended]);

  return childWithProps ? childWithProps : <Loading />;
}

/**
 * Returns an object with mapped initialize variables based on the provided step name and variables.
 *
 * @param {stepNames} name - The name of the step.
 * @param {variables} variables - The variables to be consumed.
 * @return {variables} An object with initialize variables.
 */
const getInitializeVariables = ({ name, variables }: { name: stepNames; variables?: variables }) => {
  return initializeVariablesMap[name]?.reduce((acc, { local: localVars, remote }) => {
    const local = localVars.find((v) => variables?.[v] !== undefined);
    return { ...acc, [remote]: local ? variables?.[local] : undefined };
  }, {} as variables);
};
