'use client';
import { RootState } from '@/redux/store';
import { Button, Stack, Typography } from '@mui/material';
import { rejection } from '@repo/onb-api';
import { Doodles, SafeSpacePadder } from '@repo/ui';
import { deleteClientCookie, formatter, getClientCookie } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';

export default function Rejection({ stepdata: stepData }: { stepdata?: rejection['variables'] }) {
  const { firstName } = useSelector((state: RootState) => state.flowdata.variables) || {};
  const { policyMaximumAmount } = stepData || {};
  const router = useRouter();
  const internal = getClientCookie('internal') === 'true';
  return (
    <SafeSpacePadder
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        gap: 4,
        height: '100%',
        width: '100%',
        paddingBottom: { xs: 4, md: 8 },
      }}
    >
      <Stack alignItems="center" gap={4} height={'100%'} justifyContent={'center'}>
        <Doodles type="end" />
        <Stack gap={2}>
          <Typography variant="h1">Lo sentimos {formatter(firstName, 'capitalize')}</Typography>
          {process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas' && policyMaximumAmount ? (
            <>
              <Typography variant="h1" color="primary">
                Podés comprar hasta {formatter(policyMaximumAmount, 'currency')}
              </Typography>
              <Typography color="secondary">y tu carrito excede ese importe.</Typography>
            </>
          ) : (
            <Typography color="secondary">
              En este momento no encontramos una oferta para vos. Podés intentarlo en otro momento y seguro te vamos
              ayudar.
            </Typography>
          )}
        </Stack>
      </Stack>
      {!internal &&
        (process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas' ? (
          <Button
            fullWidth
            variant="inverted"
            onClick={() => {
              const returnURLError = getClientCookie('returnURLError');
              deleteClientCookie('returnURLError');
              deleteClientCookie('returnURLSuccess');
              router.push(returnURLError || '/');
            }}
          >
            Volver a la tienda
          </Button>
        ) : (
          <Button
            fullWidth
            variant="inverted"
            onClick={() => {
              router.push('/');
            }}
          >
            Volver al Inicio
          </Button>
        ))}
    </SafeSpacePadder>
  );
}
