'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { pushStepVariables } from '@/redux/flowSlice';
import { RootState } from '@/redux/store';
import { Button, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { emailVerification } from '@repo/onb-api';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function EmailVerification({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname?: emailVerification['name'];
  stepdata?: emailVerification['variables'];
}): JSX.Element {
  const otpRef = useRef<HTMLInputElement[] | null[]>([null]);
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.flowdata.variables?.email);
  return (
    <StepWrapper>
      <Typography variant="h1">Validemos tu email</Typography>
      <Stack>
        <Typography variant="h2">
          Te mandamos un código a: <span style={{ fontWeight: 'bold' }}>{email}</span>
        </Typography>
        <Button
          variant="text"
          size="small"
          sx={{ width: 'max-content' }}
          onClick={() => alert('@todo: Edit email screen')}
        >
          Cambiar email
        </Button>
      </Stack>
      <OnbForm<emailVerification> stepName={stepName!}>
        <Stack gap={1}>
          {/* @todo: Handle error state on invalid code */}
          <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
            {[...Array(4)].map((_, index) => (
              <TextField
                key={index}
                autoFocus={index === 0}
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    maxLength: 1,
                    style: { fontSize: 38, textAlign: 'center' },
                    'data-testid': `otp-${index + 1}`,
                  },
                  input: {
                    sx: {
                      width: '64px',
                      height: '64px',
                    },
                  },
                }}
                inputRef={(el) => (otpRef.current[index] = el)}
                onPaste={(e) => {
                  if (e.clipboardData.getData('Text') && stepName) {
                    dispatch(
                      pushStepVariables({
                        stepName,
                        variable: 'smsOtp',
                        value: e.clipboardData.getData('Text').replaceAll(/\D+/g, '').slice(0, 4),
                      }),
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' && index > 0) {
                    otpRef.current[(stepData?.emailOtp?.length || 0) - 1]?.select();
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key !== 'Backspace' && (e.target as HTMLInputElement).value && index < 4) {
                    otpRef.current[index + 1]?.select();
                  }
                }}
                sx={{ width: 'min-content' }}
                {...connectedField({
                  stepName,
                  name: 'smsOtp',
                  required: true,
                  beforeChange: (value) =>
                    (stepData?.emailOtp?.substring(0, index) || '') +
                    value.replaceAll(/\D+/g, '') +
                    (stepData?.emailOtp?.substring(index + 1) || ''),
                  beforeRender: (value) => value?.[index] ?? '',
                })}
              />
            ))}
          </Grid>
          <Button variant="text" onClick={() => alert('@todo: Add resend code logic')}>
            Reenviar código
          </Button>
        </Stack>
      </OnbForm>
    </StepWrapper>
  );
}
