import { apiModel } from '@repo/api';
import { api } from '@repo/api/src';
import { onbApiResponse } from './types';

/**
 * Creates an instance of the onbApi function that can be used to make API requests.
 *
 * @param {Omit<apiModel, 'baseUrl'>} props - The properties to be passed to the api function.
 * @param {boolean} forceMock - Whether to use the mock API or not.
 * @return {Promise<onbApiResponse>} A promise that resolves to the response of the API request.
 */
export const onbApi = <T = onbApiResponse>({ forceMock, ...rest }: Omit<apiModel, 'baseUrl'>) =>
  api<T>({
    additionalHeaders: { apiKey: process.env.NEXT_PUBLIC_ONB_API_KEY },
    ...rest,
    baseUrl: process.env.NEXT_PUBLIC_ONB_API_ROUTE || '',
    forceMock: process.env.NEXT_PUBLIC_ONB_API_FORCEMOCK === 'true' ? true : forceMock,
  });

export * from './constants';
export * from './services';
export type * from './types';
